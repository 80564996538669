<template>
  <div>
    <v-dialog v-model="dialog_enter_safe_key" max-width="600px" persistent>
      <v-card>
          <v-toolbar :color="$store.state.theme.primary" dark>
            <v-btn icon @click="dialog_enter_safe_key = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Dein Safe-Schlüssel wird benötigt</v-toolbar-title>
          </v-toolbar>
          
          <v-card-text class="mt-5 pb-0">
              <v-row class="mb-0 pb-0">
                  <v-col class="mb-0 pb-0">
                      <div class="text-h6">Dein persönlicher Safe-Schlüssel</div>
                      <p class="subtitle-1 text-justify">
                          Um die personenbezogenen Daten (z.B. Name, Adresse, E-Mail Adresse etc.) deiner Klienten
                          zu entschlüsseln, wird dein Safe-Schlüssel benötigt. Diesen Schlüssel hast du bei der Registrierung festgelegt.
                      </p>
                      
                      <v-text-field
                          v-model="$store.state.data_key"
                          label="Safe-Schlüssel"
                          :append-icon="show_key ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show_key ? 'text' : 'password'"
                          @click:append="show_key = !show_key"
                          :rules="[rules.required, rules.min]"
                          outlined
                          dense
                      ></v-text-field>
                  </v-col>
              </v-row>
          </v-card-text>
          
          <v-card-actions class="justify-end px-6 pb-5">
              <v-btn :color="$store.state.theme.red" text @click="dialog_enter_safe_key = false" class="mr-3">
                  Abbrechen
              </v-btn>
              <v-btn
                  :disabled="!specifiedSafeKey"
                  :loading="loading_key"
                  :color="$store.state.theme.green" outlined
                  @click="save_safe_key"
              >
                  Speichern
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="info" :timeout="info_timeout" :color="$store.state.theme.green">
      <div class="d-flex align-center">
        <v-progress-circular v-if="info_progress" indeterminate color="white" size="20" class="mr-3"></v-progress-circular>
        <div>{{ info_message }}</div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="info = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="error" :timeout="error_timeout" color="red">
      {{ error_message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-if="additional_button" text v-bind="attrs" @click="navigateToAdditionalButton">
          {{ additional_button_text }}
        </v-btn>
        <v-btn text v-bind="attrs" @click="error = false">
          <v-icon v-if="additional_button">mdi-close</v-icon>
          <span v-else>Schließen</span>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '../helpers/cipher.js'
import { supabase } from '../supabase';

export default {
    props: ['session'],
    data() { 
      return {
        info_progress: false,

        dialog_enter_safe_key: false,
        loading_key: false,
        show_key: true,
        rules: {
          required: (v) => !!v || 'Pflichtfeld.',
          min: (v) => (!!v && v.length >= 6) || 'Mindestens 6 Zeichen.',
        },

        last_error_logged_was_wrong_key: null,
        error: false,
        error_message: '',

        info: false,
        info_message: '',
        info_timeout: -1,

        additional_button: false,
        additional_button_target: '',
        additional_button_text: '',

        error_timeout: -1,
      }
    },

    methods: {
    
      async navigateToAdditionalButton() {
        if (this.additional_button_target === 'reload') {
          window.location.reload(true);
          return;
        } else if (this.additional_button_target === 'manage-subscription') {
          
          if (this.$store.getters.getTrueSubscriptionTier) {
            // redirect to customer portal
            let function_name = 'create-stripe-customer-portal';
            if (process.env.NODE_ENV === 'development') {
                function_name = 'create-stripe-customer-portal-test';
            }
            const { data, error } = await supabase.functions.invoke(function_name);

            if (error) {
                console.error(error);
                return;
            }

            window.location.href = data?.url;
          }

          return;
        } else if (this.additional_button_target === 'logout') {
          await this.handleLogout();
          this.error = false;
          return;
        }
        this.$router.push(this.additional_button_target);
        this.error = false;
      },

      showError(error) {
        
        if ('message' in error) {
          this.error_message = error['message']

          if ('additional_button' in error) {
            this.additional_button = true
            this.additional_button_target = error['additional_button']['target']
            this.additional_button_text = error['additional_button']['text']
          } else {
            this.additional_button = false
            this.additional_button_target = ''
            this.additional_button_text = ''
          }

          if ('timeout' in error) {
            this.error_timeout = error['timeout']
          } else {
            this.error_timeout = -1
          }

        } else {
          this.error_message = JSON.stringify(error)
        }

        // check if Load failed error, if so show proper error message
        if (this.error_message.includes('Load failed') || this.error_message.includes('Failed to fetch')) {
          // before overwriting the error_message, store it in error key, so that it gets logged
          error.error = this.error_message;

          this.error_message = 'Es konnte keine Verbindung hergestellt werden. Bitte versuche es noch einmal.';
          this.error_timeout = 10000;
          this.additional_button = true;
          this.additional_button_target = 'reload';
          this.additional_button_text = 'Seite neu laden';

        } else if (this.error_message.includes('JWT expired')) {
          error.error = this.error_message;

          this.error_message = 'Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.';
          // this.error_timeout = 10000;
          this.additional_button = true;
          this.additional_button_target = 'logout';
          this.additional_button_text = 'Neu Einloggen';
        }

        // show the dialog to enter the safe key, otherwise show the error
        if (this.additional_button_text === 'Safe-Schlüssel ändern') {
          this.dialog_enter_safe_key = true
        } else {
          // console.log(error)
          this.error = true
        }

        // log the error to the server 
        if (this.last_error_logged_was_wrong_key && this.additional_button_text === 'Safe-Schlüssel ändern') {
          // do not log the error again
          return
        } 

        // only log the error if the user is logged in
        if (this.session && this.session.user && this.session.user.id) {
          connector.logError(this, {
            uid: this.session.user.id,
            message: this.error_message + (error.error ? ' ' + error.error : ''),
          })
        }
        
        // remember if the last error was a wrong key
        this.last_error_logged_was_wrong_key = this.additional_button_text === 'Safe-Schlüssel ändern'
      },

      showInfo(info) {
        if (info === false) {
          this.info = false;
          this.info_progress = false;
          return;
        } else if (typeof info === 'string') {
          this.info_message = info;
          this.info_timeout = -1;
          this.info_progress = false;
        } else {
          this.info_message = info['message'];

          if ('timeout' in info) {
            this.info_timeout = info['timeout'];
          } else {
            this.info_timeout = -1;
          }

          if ('progress' in info) {
            this.info_progress = info['progress'];
          } else {
            this.info_progress = false;
          }
        }
        this.info = true;
      },

      async handleLogout() {
          try {
              const { error } = await supabase.auth.signOut();
              if (error) throw error;
              if (this.$route.path !== '/') this.$router.push('/');
          } catch (error) {
              console.log(error);
              localStorage.removeItem('sb-api-auth-token');
              if (this.$route.path !== '/') this.$router.push('/');
          } 
      },

      async save_safe_key() {
          this.loading_key = true;

          localStorage.data_key = this.$store.state.data_key;

          //let passwordKey = await cipher.getPasswordKey(this.$store.state.data_key);
          //let aesKey = await cipher.deriveKey(passwordKey, ['encrypt', 'decrypt']);

          let keys = await cipher.getAESKeys(this);
          let aesKey = keys['aes_key'];
          let aesKeyFile = keys['aes_key_file'];

          // check if the key is correct by trying to decrypt some client data
          let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true)
          let decrypted_customer = await cipher.decryptObject(this, aesKey, customers[0], false)
          if (decrypted_customer['nachname'] === "********") {
            this.showError({
              message: 'Der angegebene Safe-Schlüssel ist falsch. Bitte versuche es erneut.',
              timeout: 5000,
            })
            this.loading_key = false;
            return
          }

          this.$store.state.aes_key = aesKey;
          this.$store.state.aes_key_file = aesKeyFile;
          this.loading_key = false;
          this.dialog_enter_safe_key = false;
          window.location.reload(true);
      },
    },

    computed: {
      specifiedSafeKey() {
          return this.$store.state.data_key.length >= 6;
      },
    }
}
</script>