<template>
    <v-dialog v-if="type" v-model="show" :max-width="800" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card-title class="text-h6">{{ title }} bearbeiten</v-card-title>
            <v-card-text>
                <v-form v-model="valid" ref="form">
                    <v-row class="mt-2">
                        <v-col class="my-0 pt-0 pb-3">
                            <v-checkbox :input-value="selected_receiver === type" @change="handleReceiverChange"
                                label="Als Rechnungsempfänger festlegen" dense></v-checkbox>
                        </v-col>
                        <v-col class="my-0 pt-0 pb-3">
                            <v-checkbox :input-value="selected_insured_with === type" @change="handleInsuredWithChange"
                                :label="`Klient ist bei ${title} mitversichert`" dense></v-checkbox>
                        </v-col>
                    </v-row>

                    <!-- Personal Details -->
                    <p class="d-flex align-center text-subtitle-1 font-weight-medium mb-5">
                        Name
                        <v-divider class="mx-2" />
                        <v-btn small text color="primary" @click="copyClientLastname" class="caption">
                            <v-icon small left>mdi-content-copy</v-icon>
                            Nachname vom Klienten übernehmen
                        </v-btn>
                    </p>
                    <v-row>
                        <v-col class="my-0 py-0" cols="12" sm="6">
                            <v-text-field v-model="editedItem[`${type}_titel_vorgestellt`]" label="Titel (vorgestellt)"
                                dense outlined />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="6">
                            <v-text-field v-model="editedItem[`${type}_titel_nachgestellt`]"
                                label="Titel (nachgestellt)" dense outlined />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="6">
                            <v-text-field v-model="editedItem[`${type}_vorname`]" label="Vorname" dense outlined
                                :rules="nameRules" />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="6">
                            <v-text-field v-model="editedItem[`${type}_nachname`]" label="Nachname" dense outlined
                                :rules="nameRules" />
                        </v-col>
                    </v-row>

                    <!-- Address -->
                    <p class="d-flex align-center text-subtitle-1 font-weight-medium mt-4 mb-5">
                        Adresse
                        <v-divider class="mx-2" />
                        <v-btn small text color="primary" @click="copyClientAddress" class="caption">
                            <v-icon small left>mdi-content-copy</v-icon>
                            {{ $vuetify.breakpoint.xsOnly ? '' : 'Adresse' }} vom Klienten übernehmen
                        </v-btn>
                    </p>
                    <v-row>
                        <v-col class="my-0 py-0" cols="12" sm="5">
                            <v-text-field v-model="editedItem[`${type}_adresse`]" label="Straße & Nr." dense outlined :rules="addressRules" />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="2">
                            <v-text-field v-model="editedItem[`${type}_plz`]" label="PLZ" dense outlined :rules="addressRules" />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="5">
                            <v-text-field v-model="editedItem[`${type}_ort`]" label="Ort" dense outlined :rules="addressRules" />
                        </v-col>
                    </v-row>

                    <!-- Contact Information -->
                    <p class="d-flex align-center text-subtitle-1 font-weight-medium mt-4 mb-5">
                        Kontaktdaten
                        <v-divider class="mx-2" />
                        <v-btn small text color="primary" @click="copyClientContactInfo" class="caption">
                            <v-icon small left>mdi-content-copy</v-icon>
                            {{ $vuetify.breakpoint.xsOnly ? '' : 'Kontaktdaten' }} vom Klienten übernehmen
                        </v-btn>
                    </p>
                    <v-row>
                        <v-col class="my-0 py-0" cols="12" sm="6">
                            <v-text-field v-model="editedItem[`${type}_email`]" prepend-inner-icon="mdi-email-outline"
                                label="E-Mail" dense outlined />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="6">
                            <v-text-field v-model="editedItem[`${type}_telefon`]" prepend-inner-icon="mdi-phone-in-talk"
                                label="Telefon" dense outlined />
                        </v-col>
                    </v-row>

                    <!-- Insurance Information -->
                    <p class="d-flex align-center text-subtitle-1 font-weight-medium mt-4 mb-5">
                        Versicherungsinformationen
                        <v-divider class="ml-2" />
                    </p>
                    <v-row>
                        <v-col class="my-0 py-0" cols="12" sm="4">
                            <v-text-field dense outlined v-model="editedItem[`${type}_geburtsdatum`]"
                                label="Geburtsdatum" hint="Format: TT.MM.JJJJ" prepend-inner-icon="mdi-calendar"
                                :rules="[rules_birthday.datum]" @input="handleBirthDateInput" maxlength="10" />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="4">
                            <v-text-field v-model="editedItem[`${type}_svnr`]" label="Sozialversicherungs-Nr." dense
                                outlined hint="Format: NNNN TTMMJJ" :rules="svnrRules" />
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="4">
                            <v-autocomplete v-model="editedItem[`${type}_versicherungsträger`]" :items="insurer"
                                item-text="displayName" item-value="id" label="Versicherungsträger" dense outlined />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="px-6 pb-5">
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.theme.primary" text @click="close">Abbrechen</v-btn>
                <v-btn :color="$store.state.theme.green" outlined :disabled="!valid" @click="save" :loading="saving">
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'PersonEditDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: null,
            validator: value => value === null || ['vater', 'mutter', 'partner'].includes(value)
        },
        editedItem: {
            type: Object,
            required: true
        },
        selected_receiver: {
            type: String,
            default: ''
        },
        selected_insured_with: {
            type: String,
            default: ''
        },
        insurer: {
            type: Array,
            default: () => []
        },
        rules_birthday: {
            type: Object,
            required: true
        },
        saving_persons: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            valid: true,
            requiredRule: [v => !!v || 'Dieses Feld ist erforderlich'],
        }
    },

    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                if (!value) {
                    // When dialog is closed, emit event to reset type
                    this.$emit('update:type', null);
                }
            }
        },
        title() {
            const titles = {
                vater: 'Vater',
                mutter: 'Mutter',
                partner: 'Partner'
            };
            return titles[this.type] || '';
        },
        saving() {
            return this.saving_persons;
        },

        nameRules() {
            // Required if either receiver or insured with is selected
            return this.selected_receiver === this.type || this.selected_insured_with === this.type
                ? this.requiredRule
                : []
        },

        addressRules() {
            // Required only if receiver is selected
            return this.selected_receiver === this.type
                ? this.requiredRule
                : []
        },

        svnrRules() {
            // Required only if insured with is selected
            if (this.selected_insured_with === this.type) {
                return this.requiredRule;
            }
            return [];
        }
    },

    watch: {
        // Trigger form validation when checkbox states change
        selected_receiver() {
            this.$nextTick(() => {
                this.$refs.form?.validate();
            });
        },
        selected_insured_with() {
            this.$nextTick(() => {
                this.$refs.form?.validate();
            });
        }
    },

    methods: {
        copyClientLastname() {
            const personType = this.type;
            this.editedItem[`${personType}_nachname`] = this.editedItem.nachname;
        },

        copyClientAddress() {
            const personType = this.type;
            this.editedItem[`${personType}_adresse`] = this.editedItem.adresse;
            this.editedItem[`${personType}_plz`] = this.editedItem.plz;
            this.editedItem[`${personType}_ort`] = this.editedItem.ort;
        },

        copyClientContactInfo() {
            const personType = this.type;
            this.editedItem[`${personType}_email`] = this.editedItem.email;
            this.editedItem[`${personType}_telefon`] = this.editedItem.telefon;
        },

        handleBirthDateInput(value) {
            const personType = this.type;

            if (!value) {
                this.editedItem[`${personType}_geburtsdatum`] = '';
                return;
            }

            // Remove all non-digits
            let cleaned = value.toString().replace(/\D/g, '');

            // Limit to 8 digits
            cleaned = cleaned.slice(0, 8);

            // Format with dots
            let formatted = cleaned;
            if (cleaned.length > 0) {
                formatted = cleaned.replace(/^(\d{0,2})(\d{0,2})(\d{0,4}).*/, (match, d, m, y) => {
                    let result = '';
                    if (d) result += d;
                    if (m) result += '.' + m;
                    if (y) result += '.' + y;
                    return result;
                });
            }

            this.editedItem[`${personType}_geburtsdatum`] = formatted;
        },

        close() {
            this.$emit('close', true);
        },

        save() {
            // Validation checks
            if (this.$refs.form.validate() && this.validateRequiredFields()) {
                this.$emit('save');
            }
        },

        validateRequiredFields() {
            const personType = this.type;

            // Check receiver validation
            if (this.selected_receiver === personType) {
                const requiredReceiverFields = [
                    `${personType}_vorname`,
                    `${personType}_nachname`,
                    `${personType}_adresse`,
                    `${personType}_plz`,
                    `${personType}_ort`
                ];

                const missingReceiverFields = requiredReceiverFields.filter(field =>
                    !this.editedItem[field]
                );

                if (missingReceiverFields.length > 0) {
                    this.$emit('showError', {
                        message: `Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, Adresse, PLZ und Ort) für den Rechnungsempfänger "${this.title}" aus.`
                    });
                    return false;
                }
            }

            // Check insured with validation  
            if (this.selected_insured_with === personType) {
                const requiredInsuredFields = [
                    `${personType}_vorname`,
                    `${personType}_nachname`,
                    `${personType}_svnr`
                ];

                const missingInsuredFields = requiredInsuredFields.filter(field =>
                    !this.editedItem[field]
                );

                if (missingInsuredFields.length > 0) {
                    this.$emit('showError', {
                        message: `Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, und Sozialversicherungsnummer) für den Versicherten "${this.title}" aus.`
                    });
                    return false;
                }
            }

            return true;
        },

        handleReceiverChange() {
            this.$emit('select', this.type);
        },

        handleInsuredWithChange() {
            this.$emit('select-insured-with', this.type);
        },
    }
}
</script>