import connector from "../helpers/supabase-connector.js";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import InspectModule from "docxtemplater/js/inspect-module";
import { saveAs } from "file-saver";
import dayjs from "dayjs";

export default {

  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  },

  formatDate(date) {
    if (!date) return null;
    return dayjs(date).format("DD.MM.YYYY");
  },

  formatTime(time) {
    if (!time) return null;
    return dayjs(time, "HH:mm:ss").format("HH:mm");
  },

  formatTimeEnd(time, duration) {
    if (!time) return null;
    return dayjs(time, "HH:mm:ss").add(duration, 'minute').format("HH:mm");
  },

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      // Create a new FileReader object
      const reader = new FileReader()

      // Add an event listener to the reader to handle the load event
      reader.addEventListener('load', () => {
        // Get the ArrayBuffer from the reader result
        const buffer = reader.result

        // Resolve the Promise with the ArrayBuffer
        resolve(buffer);
      })

      // Add an event listener to the reader to handle the error event
      reader.addEventListener('error', () => {
        // Reject the Promise with the error message
        reject(reader.error)
      });

      // Read the contents of the file as an ArrayBuffer
      reader.readAsArrayBuffer(file)
    })
  },

  async uploadTimeConfirmationTemplate(component, selectedFile) {

    // not needed since we are not using the pdf gen function
    // if (selectedFile.size > 1000000) {
    //     this.$emit('showError', {message: 'Die Datei ist zu groß. Bitte wähle eine Datei mit einer Größe von maximal 1 MB aus. Falls du Bilder in deiner Rechnung hast, füge diese als JPEG hinzu.'});
    //     this.$refs.uploader.value = ''
    //     return
    // }

    // check if file is docx
    if (selectedFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      component.$emit('showError', {
        message: 'Die Datei ist kein Word-Dokument. Bitte wähle eine Datei mit der Endung .docx aus.',
        timeout: 7000
      });
      return false;
    }

    try {
      // check if file contains at least the following tags using the docxtemplater library and InspectModule
      // 'vorname', 'nachname', 'termin_datum'
      const iModule = InspectModule();
      const buffer = await this.readFileAsync(selectedFile);
      const zip = new PizZip(buffer);
      const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [iModule],
      });

      const tags = iModule.getAllTags();
      let template_tags = Object.keys(tags);
      let required_tags = [
        'vorname', 'nachname', 'termin_datum'
      ];
      let missing_tags = required_tags.filter((tag) => !template_tags.includes(tag));

      if (missing_tags.length > 0) {
        component.$emit('showError', { 
            message: 'Die Vorlage enthält nicht alle benötigten Pflichtfelder für eine vollständige Rechnung. Bitte füge die folgenden Pflichtfelder in die Vorlage ein: ' + 
                      missing_tags.map((tag) => '{' + tag + '}').join(', ')
          });
          return false;
      }

      let name = "custom-appointment-template.docx";

      let files = await connector.uploadFileToBucket(component, 'appointment-templates', component.session.user.id + '/', name, selectedFile);
      if (files === null) {
        // Error during upload, error has been shown.
        connector.logError(this, {
            uid: component.session.user.id,
            message: 'LOG: Possible error during invoice template upload.',
        });
        return false;
      } else {
        return true;
      }
    }
    catch (error) {
        let message = 'Die Vorlage konnte nicht gelesen werden. Vermutlich enthält die Vorlage fehlerhafte Platzhalter. Wende dich per E-Mail an contact@zeipsy.com für Unterstützung.';
        let error_msg = error;
        // check if error contains prop error, and if the prop is an array, if so add the first error message to the message
        if (error.properties && Array.isArray(error.properties.errors) && error.properties.errors.length > 0) {
          error_msg = error.properties.errors[0].message;
        }
        component.$emit('showError', {
          message: message,
          timeout: 1000,
          error: error_msg
        });
        return false;
    }

  },

  async getSampleTimeConfirmation(component, template, to_pdf = false, download = true) {
    let item = {
      vorname: "Max",
      nachname: "Mustermann",
      datum: dayjs().format('YYYY-MM-DD'),
      uhrzeit: "12:00:00",
      dauer: 50
    }
    return this.generateTimeConfirmation(component, template, item, to_pdf, download);
  },

  async getCustomTimeconfirmationTemplate(component) {

    try {
      let files = await connector.listFilesInBucket(component, 'appointment-templates', component.session.user.id + '/');
      if (files === -1) {
        // error has already been shown
        files = [];
      }
      let filtered_files = [...files.filter(file => file.id !== null && file.name !== '.emptyFolderPlaceholder')]
      filtered_files = filtered_files.map((file) => {
        file.updated_at_formatted = dayjs(file.updated_at).format('DD.MM.YY HH:mm');
        file.updated_at = dayjs(file.updated_at).toISOString();
        if (file.name === 'custom-appointment-template.docx') {
          file.displayName = 'Eigene Zeitbestätigungs-Vorlage';
        }
        file.size = this.bytesToSize(file.metadata.size);
        file.uploading = false;
        file.previewing = false;
        file.downloading = false;
        return file;
      });
      if (filtered_files.length > 0) {
        return filtered_files[0];
      } else {
        return null;
      }
    } catch (error) {
      component.$emit('showError', { 
        message: 'Fehler beim Laden der Zeitbestätigungsvorlagen. Bitte versuche es erneut.',
        timeout: 7000,
        error: error
      });
      return null;
    }
  },

  async generateTimeConfirmation(component, template, item, to_pdf = false, download = true) {
    try {
      let data = {};

      data.vorname = item.vorname;
      data.nachname = item.nachname;
      data.datum = dayjs().format('DD.MM.YYYY');
      data.termin_datum = this.formatDate(item.datum);
      data.termin_uhrzeit_start = this.formatTime(item.uhrzeit);
      data.termin_uhrzeit_ende = this.formatTimeEnd(item.uhrzeit, item.dauer);
      data.praxis_name = component.$store.state.client.name;

      // Vorlage laden
      let bucket = "public-templates";
      let filename = "appointment_confirmation.docx";
      let path = "appointments/";

      if (template && template.updated_at) {
        bucket = "appointment-templates";
        path = component.session.user.id + '/';
        filename = "custom-appointment-template.docx";
        let updated_at = '?updated_at=' + template.updated_at;
        filename = filename + updated_at;
      }

      let content = await connector.downloadFile(component, bucket, path, filename);

      if (content === null) {
        // Fehler wurde bereits angezeigt
        return {
          status: "error",
          type: "load_failed",
        };
      }

      // Platzhalter ersetzen
      let undefined_tags = [];

      const iModule = InspectModule();
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        modules: [iModule],
        parser: function (tag) {
          return {
            get: function (scope) {
              let result = null;
              if (tag === ".") {
                result = scope;
              } else {
                result = scope[tag.toLowerCase()];
              }
              return result;
            },
          };
        },
        nullGetter: function (part, scopeManager) {
          undefined_tags.push(part);
          return "";
        },
      });

      const tags = iModule.getAllTags();
      let template_tags = Object.keys(tags);

      // Überprüfen, ob alle erforderlichen Daten vorhanden sind
      let missing_tag = false;
      let missing_tags = [];

      template_tags.forEach((tag) => {
        tag = tag.toLowerCase();
        if (
          !(tag in data) ||
          data[tag] === null ||
          data[tag] === undefined ||
          data[tag] === ""
        ) {
          missing_tags.push(tag);
          missing_tag = true;
        }
      });

      if (missing_tag) {
        component.$emit("showError", {
          message:
            "Es fehlen folgende Daten für die Zeitbestätigung: " +
            missing_tags.join(", "),
        });
        return {
          status: "error",
          type: "missing_tags",
          missing_tags: missing_tags,
        };
      }

      // Dokument rendern
      doc.render(data);

      // Überprüfen auf undefinierte Platzhalter
      if (undefined_tags.length > 0) {
        component.$emit("showError", {
          message:
            "Die folgenden Platzhalter wurden nicht definiert: " +
            undefined_tags.map((tag) => "{" + tag.value + "}").join(", ") +
            ". Bitte überprüfe die Vorlage.",
        });
        return {
          status: "error",
          type: "undefined_tags",
          undefined_tags: undefined_tags.map((tag) => tag.value),
        };
      }

      const outputBlob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      let filenameOutput =
        "Zeitbestaetigung_" + data.nachname + "_" + data.vorname;

      if (to_pdf) {
        return await this.convertToPdf(component, outputBlob, filenameOutput, download);
      } else {
        if (download) {
          await saveAs(outputBlob, filenameOutput + ".docx");
        }
        return {
          status: "success",
          blob: outputBlob,
          filename: filenameOutput + ".docx",
        };
      }
    } catch (error) {
      console.error("Fehler beim Generieren der Zeitbestätigung:", error);
      component.$emit("showError", {
        message: "Fehler beim Generieren der Zeitbestätigung: " + error.message,
      });
      return {
        status: "error",
        type: "exception",
        error: error,
      };
    }
  },

  async convertToPdf(component, blob, filename, download) {
    const formdata = new FormData();
    formdata.append("files", new File([blob], "time_confirmation.docx"));

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + component.session.access_token
    );
    myHeaders.append("Accept", "*/*");
    myHeaders.append("x-client-info", "supabase-js/2.8.0");
    myHeaders.append("x-region", "eu-central-1");

    const requestOptions = {
      headers: myHeaders,
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    let attempts = 2;

    while (attempts > 0) {
      try {
        let response = await fetch(
          "https://api.zeipsy.com/functions/v1/proxy/forms/libreoffice/convert",
          requestOptions
        );
        if (response.ok) {
          const pdfBlob = await response.blob();
          if (download) {
            await saveAs(pdfBlob, filename + ".pdf");
          }
          return {
            status: "success",
            blob: pdfBlob,
            filename: filename + ".pdf",
          };
        } else {
          throw new Error(response.status);
        }
      } catch (error) {
        if (
          attempts > 1 &&
          error.message &&
          (error.message.includes("503") ||
            error.message.includes("504") ||
            error.message.includes("500"))
        ) {
          attempts--;
          if (
            component.session &&
            component.session.user &&
            component.session.user.id
          ) {
            connector.logError(component, {
              uid: component.session.user.id,
              message: "Silent Error during timeConfirmation2pdf: " + error,
            });
          }
          continue;
        }
        component.$emit("showError", {
          message: `Die Zeitbestätigung konnte nicht heruntergeladen werden. Bitte versuche es erneut.`,
          error: error,
          timeout: 10000,
        });
        return {
          status: "error",
          type: "conversion_failed",
        };
      }
    }
  },
};