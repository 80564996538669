<template>
  <v-container fluid>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Soll dieser Eintrag wirklich gelöscht werden?</v-card-title>
        <v-card-actions class="px-6 pb-5">
          <v-spacer></v-spacer>
          <v-btn :color="$store.state.theme.primary" :disabled="deleting_service" text @click="closeDelete">Nein</v-btn>
          <v-btn :color="$store.state.theme.red" :loading="deleting_service" outlined @click="deleteItemConfirm">Dienstleistung löschen</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters :class="$vuetify.breakpoint.xs ? 'pt-3' : ''">
      <v-col class="d-flex align-center justify-space-between">
        <span :class="$vuetify.breakpoint.xsOnly ? 'text-h6 font-weight-regular' : 'text-h5'">
          <span>{{ n_services }}</span>
          Dienstleistungen 
        </span>
        <v-text-field v-model="search" class="ml-5" prepend-inner-icon="mdi-magnify" label="Suche" single-line hide-details outlined dense clearable clear-icon="mdi-close-circle"/>
        <v-btn v-if="$vuetify.breakpoint.smAndUp" class="ml-5" elevation="1" :color="$store.state.theme.green" @click="newItem()" dark>
            <v-icon left>mdi-plus</v-icon>
            Neue Dienstleistung
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="1000px" >
      <v-card class="px-5">
        <v-row>
          <span class="my-7 px-3 text-h5">{{ formTitle }}</span>
        </v-row>
          <v-row>
            <v-col class="my-0 py-0" md="6" cols="12">
              <v-text-field dense outlined v-model="editedItem.bezeichnung" label="Bezeichnung" 
                            placeholder="z.B. Klinisch-psychologische Behandlung (Einzel)" 
                            persistent-placeholder hint="Tipp: Füge in Klammer hinzu ob Einzel- oder Gruppensetting" />
            </v-col>
            <v-col class="my-0 py-0" md="2" cols="4">
              <v-text-field dense outlined type="number" v-model="editedItem.preis" label="Preis"/>
            </v-col>
            <v-col class="my-0 py-0" md="2" cols="4">
              <v-text-field dense outlined type="number" v-model="editedItem.dauer" label="Dauer (Minuten)"/>
            </v-col>
            <v-col class="my-0 py-0" md="2" cols="4">
              <v-select
                dense
                outlined
                v-model="editedItem.farbe"
                :items="colors"
                label="Farbe"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center justify-center">
                    <div :style="{ backgroundColor: item, borderRadius: '6px', width: '24px', height: '24px' }"></div>
                  </div>
                </template>
                <!-- Customize the display of the selected item in the selection field -->
                <template v-slot:selection="{ item }">
                  <div class="d-flex align-center justify-center">
                    <div :style="{ backgroundColor: item, borderRadius: '6px', width: '24px', height: '24px'}"></div>
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0">
              <v-icon>
                mdi-information-outline
              </v-icon>
              <span class="text-body-2">
                Auf der Rechnung erscheint für jeden Termin die Bezeichnung, der Preis und die Dauer der Dienstleistung.
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-5 py-0">
              <span class="text-subtitle-1 font-weight-medium">
                Welche Umsatzsteuerbefreiung gilt für diese Dienstleistung?
              </span>
              <v-select class="pt-2 mt-2" dense outlined v-model="editedItem.ust_befreiung" :items="ust_items" label="Umsatzsteuerbefreiung" item-text="text" item-value="value" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0">
              <span class="text-subtitle-1 font-weight-medium">Erklärung zu § 6 Abs. 1 Z {{ selectedUstNumber }}</span>
              <p v-if="editedItem.ust_befreiung === 1">
                Die Umsätze aus der Tätigkeit als PsychotherapeutIn, GesundheitspsychologIn oder Klinischer PsychologIn sind umsatzsteuerbefreit gemäß § 6 Abs. 1 Z 19 UstG.
              </p>
              <p v-else-if="editedItem.ust_befreiung === 2">
                Erfolgt die Ausbildungssupervision in einer Ausbildungseinrichtung (Schule), sind die Umsätze gemäß § 6 Abs. 1 Z 11 UStG befreit.
              </p>
              <p v-else>
                Kleinunternehmer sind Unternehmer, deren Jahresumsatz 35.000 EUR jährlich nicht überschreitet.
                Nicht in diese Grenze einbezogen sind Umsätze, die der Umsatzsteuer befreit sind.
              </p>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-end mx-0 px-0 mt-5 pb-5">
            <v-btn :color="$store.state.theme.red" :disabled="inserting_service" text @click="close">
              Abbrechen
            </v-btn>
            <v-btn :color="$store.state.theme.green" :loading="inserting_service" outlined @click="save" >
              Speichern
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        :color="$store.state.theme.green"
        dark
        fixed
        bottom
        right
        fab
        @click="newItem()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-data-table 
      :headers="headers" 
      sort-by="datum" 
      :custom-filter="filterName" 
      :search="search" 
      sort-desc 
      :items="services" 
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100, -1],
      }"
      class="elevation-1 mt-3"
      @update:items-per-page="saveItemsPerPage"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2" elevation="0" icon dark small :color="$store.state.theme.primary" @click="editItem(item)">
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-btn class="mx-2" elevation="0" icon dark small color="#f44336" @click="deleteItem(item)">
          <v-icon dark>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.bezeichnung="{ item }">
        <div class="d-flex align-center" style="flex-wrap: nowrap; overflow-x: auto;">
          <div :style="{ backgroundColor: item.farbe ? item.farbe : $store.state.theme.primary, borderRadius: '6px', width: '24px', height: '24px', marginRight: '8px', flex: '0 0 auto'}"></div>
          <span>{{ item.bezeichnung }}</span>
        </div>
      </template>
      <template v-slot:item.ust_befreiung="{ item }">
        <v-icon left small v-if="item.ust_befreiung">
          {{ item.ust_befreiung === 1 ? 'mdi-stethoscope' : ( item.ust_befreiung === 2 ? 'mdi-school-outline' : 'mdi-account-tie-outline' ) }}
        </v-icon>
        <span>{{ ust_items.find(ust_item => ust_item.value === item.ust_befreiung).text }}</span>
      </template>
      <template v-slot:no-data >
        <div v-if="loading" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <p class="mt-5">Es sind noch keine Dienstleistungen angelegt.</p>
        </div>
      </template>
      <template v-slot:item.datum="{ item }">
        <span>{{ new Date(item.datum).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}) }}</span>
      </template>
      <template v-slot:item.preis="{ item }">
        <span v-if="item.preis !== null">{{ item.preis }} €</span>
      </template>
      <template v-slot:item.dauer="{ item }">
        <span v-if="item.dauer !== null">{{ item.dauer }} Minuten</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'

  export default {

    props: ['session'],

    data() { 
      return {

        itemsPerPage: parseInt(localStorage.getItem('items-per-page')) || 10,

        deleting_service: false,
        inserting_service: false,

        search: '',
        loading: false,
        dialog: false,
        dialogDelete: false,
        headers: [
          { text: 'Bezeichnung', value: 'bezeichnung' },
          { text: 'Preis', value: 'preis' },
          { text: 'Dauer', value: 'dauer' },
          { text: 'Umsatzsteuerbefreiung', value: 'ust_befreiung' },
          { text: 'Aktionen', value: 'actions', sortable: false, align: 'center' },
        ],
        
        // make sure this is in sync with the values in Rechnung.vue
        ust_items: [
          {'text': '§ 6 Abs. 1 Z 19 UstG (Behandlung)', 'value': 1},
          {'text': '§ 6 Abs. 1 Z 11 UstG (Ausbildungssupervision in einer Ausbildungseinrichtung)', 'value': 2},
          {'text': '§ 6 Abs. 1 Z 27 UstG (Kleinunternehmer)', 'value': 3}
        ],
        colors: [
          this.$store.state.theme.primary,
          '#7885cb',
          '#9475cc',
          '#4cd0e0',
          '#81c784',
          '#ffb64c',
          '#f77b72',
          '#ef6191',
          '#4cb5ab',
          '#ffd351',
          '#bbbbbb',
          '#8fa4ad'
        ],
        n_services: 0,
        services: [],
        editedIndex: -1,
        editedItem: {
          id: null,
          uid: this.session.user.id,
          bezeichnung: null,
          preis: null,
          dauer: null,
          ust_befreiung: 1,
          farbe: null,
        },
        defaultItem: {
          id: null,
          uid: this.session.user.id,
          bezeichnung: null,
          preis: null,
          dauer: null,
          ust_befreiung: 1,
          farbe: null,
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Neue Dienstleistung' : 'Dienstleistung bearbeiten'
      },

      selectedUstNumber() {
        if (this.editedItem.ust_befreiung === 1) {
          return 19
        } else if (this.editedItem.ust_befreiung === 2) {
          return 11
        } else {
          return 27
        }
      },

      computedServices() {
        return this.services.map(service => {
          return {
            ...service,
            ust_befreiung_text: this.ust_items.find(item => item.value === service.ust_befreiung).text,
          }
        })
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
      this.initialize()
    },

    methods: {

      saveItemsPerPage(value) {
        localStorage.setItem('items-per-page', value.toString());
      },

      async initialize() {
        
        this.loading = true;
        this.services = await connector.getDataOnly(this, 'vwdienstleistungen', 'id', true);
        if (this.services === -1) {
          // error has already been shown
          // set to empty array, but skip setting the onboarding status
          this.services = [];
          this.n_services = 0;
          this.loading = false;
          return;
        }
        this.n_services = this.services.length;
        if (this.n_services > 0) {
          this.$store.commit('setOnboardingServiceStatus', true);
        } else {
          this.$store.commit('setOnboardingServiceStatus', false);
        }
        this.loading = false;
      },

      newItem () {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        if (this.editedItem.farbe === null) {
          this.editedItem.farbe = this.colors[0]
        }
        this.dialog = true
      },
      
      editItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.farbe === null) {
          this.editedItem.farbe = this.colors[0]
        }
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.deleting_service = true;
        let deleted = await connector.delete(this, 'dienstleistungen', 'id', this.editedIndex);
        this.deleting_service = false;
        if (!deleted) {
          return;
        };
        await this.initialize();
        this.closeDelete();
        this.$emit('showInfo', {
          message: 'Die Dienstleistung wurde erfolgreich gelöscht.',
          timeout: 5000
        });
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      close() {
        this.dialog = false
        this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        })
      },

      async save () {

        /*id bigint generated by default as identity,
        inserted_at timestamp with time zone null default (now() at time zone 'utc'::text),
        updated_at timestamp with time zone null default (now() at time zone 'utc'::text),
        uid uuid not null,
        preis double precision not null,
        dauer double precision null,
        bezeichnung text not null,
        ust_befreiung integer not null,
        farbe text null,
        */

        let toInsert = {
          uid: this.session.user.id,
          preis: this.editedItem.preis,
          dauer: this.editedItem.dauer,
          bezeichnung: this.editedItem.bezeichnung,
          ust_befreiung: this.editedItem.ust_befreiung,
          farbe: this.editedItem.farbe,
        }; 

        // check if preis or dauer is empty - if so, replace with null. This can happen if an existing value is deleted.
        if (toInsert.dauer === '') {
          toInsert.dauer = null;
        }

        if (toInsert.preis === '') {
          toInsert.preis = null;
        }

        // check if preis is set, if not show error
        if (toInsert.preis === null) {
          this.$emit('showError', {
            message: 'Bitte gib einen Preis ein. Der Preis kann auch 0 sein.',
            timeout: 10000
          });
          return;
        }

        if (this.editedIndex > -1) {
          // updated 
          this.inserting_service = true;
          let updated = await connector.update(this, 'dienstleistungen', toInsert, this.editedItem.id);
          this.inserting_service = false;
          if (updated === null) {
            // error has already been shown
            return;
          }
          await this.initialize();
          this.close();
          this.$emit('showInfo', {
            message: 'Die Dienstleistung wurde erfolgreich aktualisiert.',
            timeout: 5000
          });

        } else {
          // create new
          this.inserting_service = true;
          let inserted = await connector.insert(this, 'dienstleistungen', toInsert);
          this.inserting_service = false;
          if (inserted === false) {
            // error has already been shown
            return;
          }
          await this.initialize();
          this.close();
          this.$emit('showInfo', {
            message: 'Die Dienstleistung wurde erfolgreich erstellt.',
            timeout: 5000
          });
        }
      },

      filterName (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          (value.toLowerCase().indexOf(search.toLowerCase()) !== -1 || 
            (item.bezeichnung.toLowerCase()).indexOf(search.toLowerCase()) !== -1
          )
      },
    }
  }
</script>