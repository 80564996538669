<template>
    <v-dialog v-model="dialog" persistent fullscreen no-click-animation @keydown.stop="handleClose">
      <v-card flat tile>
        <v-toolbar flat :color="$store.state.theme.primary">
          <v-btn icon @click="onClose">
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">
            <span>{{ title }}</span>
          </v-toolbar-title>        
          <v-spacer></v-spacer>
        </v-toolbar>
  
        <v-row no-gutters>
          <v-col cols="12" md="8" class="d-flex justify-center pa-4">
              <iframe :src="pdfUrl" :style="{ width: '100%', height: '85dvh', border: 'none' }">
              </iframe>
          </v-col>
          <v-col cols="12" md="4" class="pa-4">
              <div v-if="current_template" class="body-1 mb-3">
                <p class="text-h6">
                  {{ current_template.displayName }}
                </p>
                <span v-if="current_template && current_template.updated_at_formatted">Zuletzt bearbeitet am {{ current_template.updated_at_formatted }}</span>
              </div>
              <div v-if="current_template && current_template.size" class="body-1 mb-3">Größe: {{ current_template.size }}</div>

              <v-btn class="mr-5 mb-3" dark :color="$store.state.theme.green" @click="$emit('download')">
                <v-icon left>mdi-download</v-icon>
                Herunterladen
              </v-btn>

              <v-btn v-if="deletable" class="mr-5 mb-3" dark :color="$store.state.theme.red" @click="$emit('delete', current_template)">
                <v-icon left>mdi-delete</v-icon>
                Löschen
              </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'TemplatePreviewDialog',
    props: {
      dialog: {
        type: Boolean,
        required: true
      },
      pdfUrl: {
        type: String,
        default: null
      },
      current_template: {
        type: Object,
        required: false
      },
      title: {
        type: String,
        default: 'Vorschau'
      },
    },
    methods: {
      onClose() {
        this.$emit('close');
      },
      handleClose(event) {
        if (event.code === 'Escape') {
          this.onClose();
        }
      }
    },
    computed: {
      deletable() {
        if (this.current_template && this.current_template.updated_at) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  </script>