<template>
    <v-container fluid>
        <v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step :color="$store.state.theme.primary" :complete="e1 > 1" step="1">
                    Einführung
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :color="$store.state.theme.primary" :complete="e1 > 2" step="2">
                    Praxis-Informationen
                </v-stepper-step>

                <!--<v-divider></v-divider>

                <v-stepper-step :color="$store.state.theme.primary" :complete="e1 > 3" step="3">
                    Safe-Schlüssel
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :color="$store.state.theme.primary" :complete="e1 > 4" step="4">
                    Rechnungsnummer
                </v-stepper-step> -->
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card class="mx-auto pa-6" outlined>
                        <div class="text-h4 text-center">Willkommen bei</div>
                        <v-img class="mx-auto pa-0 mb-6" src="../assets/logo-black.svg" height="180" width="800" />

                        <div class="text-subtitle-1 text-center">
                            Bevor du loslegen kannst, müssen noch ein paar Grundeinstellungen getroffen werden. 
                            <!-- Bis auf den <b>Safe-Schlüssel</b> und die <b>Rechnungsnummerierung</b>, kannst du alle Einstellungen später noch ändern. -->
                        </div>

                        <div class="d-flex justify-end mx-5 mt-5">
                            <v-btn dark :color="$store.state.theme.primary" @click="e1 = e1 + 1">
                                Weiter
                                <v-icon right> mdi-chevron-right </v-icon>
                            </v-btn>
                        </div>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-row class="">
                        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column">
                            <div class="text-h5 mb-2">Praxis-Informationen</div>
                            <span class="text-body-1 text-justify">
                                Diese Informationen werden auf den ausgestellten Rechnungen angezeigt.
                                Du kannst die Praxis-Informationen jederzeit in den Einstellungen bearbeiten.
                            </span>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
                            <v-card class="px-5 pt-5 mx-1" outlined>
                                <v-container ma-0 pa-0 class="d-flex justify-space-between">
                                    <v-text-field
                                        dense
                                        outlined
                                        class="mr-3"
                                        v-model="$store.state.client.name"
                                        label="Name der Praxis"
                                        hint="z.B. Mag. Martina Musterfrau"
                                    />
                                    <v-text-field
                                        dense
                                        outlined
                                        class="ml-3"
                                        v-model="$store.state.client.bezeichnung"
                                        label="Berufsbezeichnung"
                                        hint="z.B. Klinische Psychologin"
                                    />
                                </v-container>
                                <v-container ma-0 pa-0 class="d-flex justify-space-between">
                                    <v-text-field
                                        dense
                                        outlined
                                        class="mr-3"
                                        v-model="$store.state.client.telefon"
                                        label="Telefonnummer"
                                    />
                                    <v-text-field
                                        dense
                                        outlined
                                        class="ml-3"
                                        v-model="$store.state.client.adresse"
                                        label="Adresse"
                                    />
                                </v-container>
                                <v-container ma-0 pa-0 class="d-flex justify-space-between">
                                    <v-text-field
                                        dense
                                        outlined
                                        type="number"
                                        class="mr-3"
                                        v-model="$store.state.client.plz"
                                        label="PLZ"
                                    />
                                    <v-text-field
                                        dense
                                        outlined
                                        class="ml-3"
                                        v-model="$store.state.client.ort"
                                        label="Ort"
                                    />
                                </v-container>
                                <!-- <v-container ma-0 pa-0 class="d-flex justify-space-between">
                                    <v-text-field
                                        dense
                                        outlined
                                        class="mr-3"
                                        v-model="$store.state.client.iban"
                                        label="IBAN"
                                    />
                                    <v-text-field
                                        dense
                                        outlined
                                        class="ml-3"
                                        v-model="$store.state.client.bic"
                                        label="BIC"
                                    />
                                </v-container> -->
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>

                    <!-- <v-row class="">
                        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column">
                            <div class="text-h5 mb-2">Dienstleistungen</div>
                            <p class="text-body-1 text-justify">
                                Lege deine erste Dienstleistung an.
                                Eine Dienstleistung ist z.B. die "Klinisch-psychologische Behandlung" oder die Erstellung eines "Eignungsgutachten".
                                Du kannst später weitere Dienstleistungen hinzufügen oder bestehende Dienstleistungen bearbeiten.
                            </p>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
                            <v-card class="pa-5 mx-1" outlined>
                                <v-container ma-0 pa-0 class="d-flex justify-space-between">
                                    <v-text-field
                                        dense
                                        outlined
                                        type="number"
                                        class="mr-3"
                                        v-model="$store.state.client.std_preis"
                                        label="Preis pro Einheit"
                                        prefix="€"
                                    />
                                    <v-text-field
                                        dense
                                        outlined
                                        type="number"
                                        class="ml-3"
                                        v-model="$store.state.client.std_dauer"
                                        label="Dauer pro Einheit (Minuten)"
                                    />
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider> -->
                    <div class="d-flex justify-end">
                        <!-- <v-btn outlined :color="$store.state.theme.primary" @click="e1 = e1 + 1" class="mr-3">
                            Überspringen
                        </v-btn> -->
                        <v-btn :color="$store.state.theme.primary" :loading="saving_customer_information" dark @click="save_customer_data">
                            Los gehts!
                            <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                    </div>
                </v-stepper-content>

                <!-- <v-stepper-content step="3">
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="d-flex flex-column wrap">
                            <div class="text-h5 mb-2">Dein persönlicher Safe-Schlüssel</div>
                            <p class="text-body-1 text-justify">
                                Die Daten-Sicherheit deiner Klienten hat höchste Priorität. Deshalb werden alle
                                personenbezogenen Daten (z.B. Name, Adresse, E-Mail Adresse etc.) der Klienten
                                verschlüsselt abgespeichert. Dazu muss einmalig ein Safe-Schlüssel festgelegt werden.
                                Wähle einen anderen Safe-Schlüssel als dein Passwort bei ZEIPSY.
                            </p>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
                            <v-card class="px-5 pt-5 mx-1" outlined>
                                <v-container ma-0 pa-0 class="d-flex justify-space-between">
                                    <v-text-field
                                        dense
                                        outlined
                                        class="pl-0"
                                        v-model="$store.state.data_key"
                                        label="Safe-Schlüssel"
                                        :append-icon="show_key ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_key ? 'text' : 'password'"
                                        @click:append="show_key = !show_key"
                                        :rules="[rules.required, rules.min]"
                                    ></v-text-field>
                                </v-container>
                                <p class="text-body-1 text-justify">
                                    <span class="font-weight-black">Achtung:</span>
                                    Dein Safe-Schlüssel wird ausschließlich auf deinem PC gespeichert. Solltest du
                                    deinen Safe-Schlüssel vergessen, können die Daten deiner Klienten nicht
                                    wiederhergestellt werden. Notiere deinen Safe-Schlüssel und verwahre ihn an einem
                                    sicheren Ort. Weiters kannst du deinen Safe-Schlüssel nicht mehr ändern, nachdem du
                                    einen Schlüssel festgelegt hast.
                                </p>
                            </v-card>
                        </v-col>
                    </v-row>
                    <div class="d-flex justify-end mt-3 mx-1">
                        <v-btn outlined :color="$store.state.theme.primary" @click="e1 = e1 - 1" class="mr-3">
                            Zurück
                        </v-btn>
                        <v-btn
                            :disabled="!specifiedSafeKey"
                            :loading="loading_key"
                            :color="$store.state.theme.primary"
                            :dark="specifiedSafeKey"
                            @click="save_safe_key"
                        >
                            Weiter
                            <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                    </div>
                </v-stepper-content> -->

                <!-- <v-stepper-content step="4">
                    <v-item-group mandatory class="mb-5">
                        <span class="text-h5 d-flex justify-center text-center mb-5"> Rechnungsnummerierung </span>
                        <v-row>
                            <v-col>
                                <v-item>
                                    <v-card
                                        :color="
                                            usingStandardSchema
                                                ? $store.state.theme.primary
                                                : $store.state.theme.background
                                        "
                                        class="d-flex flex-column align-center justify-center py-5"
                                        :dark="usingStandardSchema"
                                        height="250"
                                        @click="changeSchema(false)"
                                    >
                                        <v-chip
                                            :color="$store.state.theme.green"
                                            small
                                            v-if="usingStandardSchema"
                                            class="text-overline text-center"
                                            >Ausgewählt <v-icon small>mdi-check</v-icon></v-chip
                                        >
                                        <span v-else class="text-overline text-center">&nbsp;</span>
                                        <span class="text-overline text-center"
                                            >Fortlaufende Nummerierung pro Jahr (zb.: 5/2023)</span
                                        >
                                        <span class="text-overline text-center font-weight-black">(Empfohlen)</span>
                                        <span class="px-5"><b>Vorteile:</b></span>
                                        <div class="px-5">
                                            <ul>
                                                <li>Automatischer Vorschlag der Rechnungsnummer beim Erstellen</li>
                                                <li>Sortieren nach Rechnungsnummer</li>
                                            </ul>
                                        </div>
                                    </v-card>
                                </v-item>
                            </v-col>
                            <v-col>
                                <v-item>
                                    <v-card
                                        :color="
                                            !usingStandardSchema
                                                ? $store.state.theme.primary
                                                : $store.state.theme.background
                                        "
                                        class="d-flex flex-column align-center justify-center py-5"
                                        :dark="!usingStandardSchema"
                                        height="250"
                                        @click="changeSchema(true)"
                                    >
                                        <v-chip
                                            :color="$store.state.theme.green"
                                            small
                                            v-if="!usingStandardSchema"
                                            class="text-overline text-center"
                                            >Ausgewählt <v-icon small>mdi-check</v-icon></v-chip
                                        >
                                        <span v-else class="text-overline text-center">&nbsp;</span>
                                        <span class="text-overline text-center">Eigene Nummerierung</span>
                                        <span class="px-5"><b>Nachteile:</b></span>
                                        <div class="px-5">
                                            <ul>
                                                <li>Kein Vorschlag der Rechnungsnummer beim Erstellen</li>
                                                <li>Keine zuverlässige Sortierung nach Rechnungsnummer</li>
                                            </ul>
                                        </div>
                                    </v-card>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>
                    <div class="d-flex justify-end">
                        <v-btn
                            :disabled="loading"
                            outlined
                            :color="$store.state.theme.primary"
                            @click="e1 = e1 - 1"
                            class="mr-3"
                        >
                            Zurück
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            :color="$store.state.theme.primary"
                            :dark="!loading"
                            @click="saveInvoiceSchema"
                        >
                            Los gehts!
                        </v-btn>
                    </div>
                </v-stepper-content> -->
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js';
import cipher from '../helpers/cipher.js';

export default {
    props: ['session'],

    data() {
        return {
            usingStandardSchema: true,
            e1: 1,
            finished_import: false,
            saving_customer_information: false,
            saved_customer_information: false,
            loading_key: false,
            show_key: true,
            loading: false,
            file: null,
            progress: 0,
            rules: {
                required: (v) => !!v || 'Pflichtfeld.',
                min: (v) => (!!v && v.length >= 6) || 'Mindestens 6 Zeichen.',
            },
        };
    },

    mounted() {
        connector
            .getDataOnly(this, 'vwkunden', 'id', true)
            .then((client) => (client.length > 0 ? (this.$store.state.client = client[0]) : false));
    },

    computed: {
        specifiedSafeKey() {
            return this.$store.state.data_key.length >= 6;
        },
    },

    methods: {
        changeSchema(useCustom) {
            this.usingStandardSchema = !useCustom;
        },

        // saveInvoiceSchema() {
        //     connector
        //         .upsertRow(this, 'kunden', { id: this.session.user.id, eigenes_schema: !this.usingStandardSchema })
        //         .then((data) => {
        //             if (data.length > 0) {
        //                 this.saved_customer_information = true;
        //                 return connector.getDataOnly(this, 'vwkunden', 'id', true);
        //             } else {
        //                 this.$emit('showError', { message: 'Fehler beim Speichern der Einstellung.' });
        //                 return null;
        //             }
        //         })
        //         .then((client) => {
        //             if (client) {
        //                 this.$store.state.client = client[0];
        //                 this.$router.push('/');
        //             }
        //         });
        // },

        async save_customer_data() {
            this.saving_customer_information = true;

            let data = await connector.upsert(this, 'kunden', {
                id: this.session.user.id,
                name: this.$store.state.client.name,
                bezeichnung: this.$store.state.client.bezeichnung,
                telefon: this.$store.state.client.telefon,
                adresse: this.$store.state.client.adresse,
                plz: this.$store.state.client.plz,
                ort: this.$store.state.client.ort,
                // iban: this.$store.state.client.iban,
                // bic: this.$store.state.client.bic,
                // std_preis: this.$store.state.client.std_preis,
                // std_dauer: this.$store.state.client.std_dauer,
                eigenes_schema: false,
            });
            if (data && data.length > 0) {

                let client_data = await connector.getDataOnly(this, 'vwkunden', 'id', true);
                if (client_data.length > 0) {
                    this.saving_customer_information = false;
                    this.$store.state.client = client_data[0];
                    this.$router.push('/');
                } else {
                    this.saving_customer_information = false;
                    this.$emit('showError', { message: 'Fehler beim Abrufen der Einstellungen.' });
                }
                
            } else {
                this.saving_customer_information = false;
                // error has already been shown
            }
        },

        // save_safe_key() {
        //     this.loading_key = true;

        //     localStorage.data_key = this.$store.state.data_key;

        //     cipher
        //         .getPasswordKey(this.$store.state.data_key)
        //         .then((passwordKey) => cipher.deriveKey(passwordKey, ['encrypt', 'decrypt']))
        //         .then((aesKey) => (this.$store.state.aes_key = aesKey))
        //         .then(() => (this.loading_key = false))
        //         .then(() => this.e1++);
        // },

        logAndReturn(arr) {
            console.log(arr);
            return arr;
        },

        parseCSV(content) {
            let data_array = content.split('\n');
            let customers = {};

            async function insertInvoicesForCustomers(component, customers, uid) {
                let insertedInvoices = [];
                let insertedInvoicesIds = [];

                for (let i = 1; i < data_array.length - 1; i++) {
                    let line = data_array[i].split(';');
                    let vorname = line[0];
                    let nachname = line[1];
                    let datum = line[2];
                    let preis = line[3];
                    let nummer = line[4];
                    let datum_rechnung = line[5];
                    let bezahlt = line[6];

                    let currentCustomer = customers.filter(
                        (customer) => customer.vorname === vorname && customer.nachname === nachname
                    )[0];

                    if (nummer === '' || datum_rechnung === '') {
                        connector.insertRow(component, 'termine', {
                            fk_klienten_id: currentCustomer.id,
                            uid: uid,
                            datum: datum,
                            preis: preis,
                            dauer: 50,
                        });
                    } else if (insertedInvoices.includes(nummer)) {
                        // insert termin only
                        let rechnungs_id_idx = insertedInvoices.indexOf(nummer);

                        connector.insertRow(component, 'termine', {
                            fk_klienten_id: currentCustomer.id,
                            fk_rechnungs_id: insertedInvoicesIds[rechnungs_id_idx],
                            uid: uid,
                            datum: datum,
                            preis: preis,
                            dauer: 50,
                        });
                    } else {
                        // insert invoice and then termin

                        await connector
                            .insertRow(component, 'rechnungen', {
                                fk_klienten_id: currentCustomer.id,
                                uid: uid,
                                datum: datum_rechnung,
                                nummer: nummer,
                                bezahlt: bezahlt === '' ? null : bezahlt,
                            })
                            .then((rechnungs_id) => {
                                insertedInvoicesIds.push(rechnungs_id);
                                insertedInvoices.push(nummer);
                                connector.insertRow(component, 'termine', {
                                    fk_klienten_id: currentCustomer.id,
                                    fk_rechnungs_id: rechnungs_id,
                                    uid: uid,
                                    datum: datum,
                                    preis: preis,
                                    dauer: 50,
                                });
                            });
                    }

                    component.progress = (i / data_array.length) * 100;
                }
                console.log('finished');
                component.loading = false;
                component.finished_import = true;
            }

            function mapIdToCustomers(customers, ids) {
                let mappedCustomers = [];
                for (let i = 0; i < customers.length; i++) {
                    let currentCustomer = customers[i];
                    currentCustomer.id = ids[i].id;
                    mappedCustomers.push(currentCustomer);
                }
                return mappedCustomers;
            }

            let customersToInsert = [];

            for (let i = 1; i < data_array.length - 1; i++) {
                let line = data_array[i].split(';');
                let vorname = line[0];
                let nachname = line[1];
                let customerToInsert = {
                    uid: this.$store.state.session.user.id,
                    nachname: nachname,
                    vorname: vorname,
                };

                if (
                    customersToInsert.filter(
                        (customer) => JSON.stringify(customer) === JSON.stringify(customerToInsert)
                    ).length === 0
                ) {
                    customersToInsert.push(customerToInsert);
                }
            }

            let encrypted_customers = Promise.all(
                customersToInsert.map((customer) => cipher.encryptObject(this.$store.state.aes_key, customer))
            );
            encrypted_customers
                .then((arr) => connector.insertRows(this, 'klienten', arr))
                .then((customers) => mapIdToCustomers(customersToInsert, customers))
                .then((customers) => insertInvoicesForCustomers(this, customers, this.$store.state.session.user.id));

            return customers;
        },

        setFile(file) {
            this.file = file;
        },

        selectFile() {
            this.loading = true;
            this.handleUpload(this.file);
        },

        readUploadedFileAsText(inputFile) {
            const temporaryFileReader = new FileReader();

            return new Promise((resolve, reject) => {
                temporaryFileReader.onerror = () => {
                    temporaryFileReader.abort();
                    reject(new DOMException('Problem parsing input file.'));
                };

                temporaryFileReader.onload = () => {
                    resolve(temporaryFileReader.result);
                };
                temporaryFileReader.readAsText(inputFile);
            });
        },

        async handleUpload(event) {
            try {
                const fileContents = await this.readUploadedFileAsText(event);

                console.log(this.parseCSV(fileContents));
            } catch (e) {
                console.warn(e.message);
            }
        },
    },
};
</script>

<style></style>
