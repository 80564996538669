import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Klienten from '../views/Klienten.vue'
import Termine from '../views/Termine.vue'
import Rechnungen from '../views/Rechnungen.vue'
import Einstellungen from '../views/Einstellungen.vue'
import Kalender from '../views/Kalender.vue'
import Welcome from '../views/Welcome.vue'
import Login from '../components/Login.vue'
import Finanzen from '../views/Finanzen.vue'
import Terminplaner from '../views/Terminplaner.vue'
import Datenschutz from '../views/Datenschutz.vue'
import Upgrade from '../views/Upgrade.vue'
import Dienstleistungen from '../views/Dienstleistungen.vue'
import Dokumentation from '../views/Dokumentation.vue'
import Buchhaltung from '../views/Buchhaltung.vue'
import Formulare from '../views/Formulare.vue'
import Assistent from '../views/Assistent.vue'
import Feedback from '../views/Feedback.vue'
import PasswortVergessen from '../components/PasswortVergessen.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback
  },
  {
    path: '/assistent',
    name: 'assistent',
    component: Assistent
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome
  },
  {
    path: '/kalender',
    name: 'Kalender',
    component: Kalender
  },
  {
    path: '/klienten/:id?',
    name: 'klienten',
    component: Klienten
  },
  {
    path: '/termine',
    name: 'Termine',
    component: Termine
  },
  {
    path: '/dokumentation/:id?',
    name: 'Dokumentation',
    component: Dokumentation
  },
  {
    path: '/dienstleistungen',
    name: 'Dienstleistungen',
    component: Dienstleistungen
  },
  {
    path: '/rechnungen',
    name: 'Rechnungen',
    component: Rechnungen
  },
  {
    path: '/finanzen',
    name: 'Finanzen',
    component: Finanzen
  },
  {
    path: '/buchhaltung',
    name: 'Buchhaltung',
    component: Buchhaltung
  },
  {
    path: '/formulare',
    name: 'Formulare',
    component: Formulare
  },
  {
    path: '/einstellungen',
    name: 'Einstellungen',
    component: Einstellungen
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/terminplaner',
    name: 'terminplaner',
    component: Terminplaner
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: Datenschutz
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: Upgrade
  },
  {
    path: '/passwort-vergessen',
    name: 'passwort-vergessen',
    component: PasswortVergessen
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
