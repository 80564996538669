<template>
    <v-app>
        <v-dialog v-model="showPlanChangeDialog" persistent max-width="700">
            <v-card>
                <v-card-title>
                    Dein Abo wurde erst kürzlich geändert
                </v-card-title>
                <v-card-text class="text-body-1">
                    Du hast dein Abo in den letzten 30 Tagen bereits einmal herabgestuft. 
                    Eine Herabstufung ist nur einmal innerhalb von 30 Tagen möglich. 
                    Um ZEIPSY weiterhin nutzen zu können, führe bitte ein Upgrade auf den nächsthöheren Abo-Plan durch. 
                    Bei Fragen oder Problemen steht dir der Support gerne zur Verfügung.
                </v-card-text>
                <v-card-actions class="px-6 pb-5">
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.state.theme.primary" text @click="reload">
                        Seite neu laden
                    </v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="upgrade">
                        Zur Abo-Verwaltung
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container v-if="session === null" fluid class="justify-center align-center layout">
            <Login v-if="$route.name !== 'passwort-vergessen'" @showError="showError" @showInfo="showInfo" />
            <PasswortVergessen v-else @showError="showError" @showInfo="showInfo" />
        </v-container>
        <div v-else class="layout content-container">
            <NavigationBar
                v-if="$store.state.client.eigenes_schema !== null"
                class="hidden-md-and-down"
                :style="{ background: $store.state.theme.primary }"
                :session="session"
                @showError="showError"
                @showInfo="showInfo"
            >
            </NavigationBar>
            <v-main class="content-container">
                <v-app-bar
                    v-if="$store.state.client.eigenes_schema !== null"
                    dark
                    :color="$store.state.theme.primary"
                    class="hidden-lg-and-up"
                >
                    <v-app-bar-nav-icon dark @click.native="dialog = true"></v-app-bar-nav-icon>
                    <v-toolbar-title>Menü</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom v-if="$store.getters.isInTrialPeriod && !($store.state.client.beta_circle === true) && !$store.getters.hasPaidSubscription" open-delay="300">
                        <template v-slot:activator="{ on, attrs }" >
                            <v-badge color="orange" overlap>
                                <template v-slot:badge>
                                    {{ $store.getters.remainingTrialDays }}
                                </template>
                                <v-chip small color="white" text-color="black" @click="upgrade" v-bind="attrs" v-on="on">
                                    <v-icon left small>mdi-account-cog</v-icon>
                                    Probezeit:&nbsp;<span class="font-weight-medium">{{ $store.getters.subscriptionTier }}</span>
                                    <v-icon right small class="ml-1">mdi-chevron-right</v-icon>
                                </v-chip>
                            </v-badge>
                        </template>
                        <p class="pb-0 mb-0">
                            Du kannst noch {{ $store.getters.remainingTrialDays }} Tage alle Funktionen uneingeschränkt testen.<br/>
                            Danach wird dein Zugang automatisch auf die kostenlose Version (Geringfügig) umgestellt.<br/>
                            Außerdem kannst du hier unsere Abos einsehen und upgraden.
                        </p>
                    </v-tooltip>
                    <div v-else :class="'gradient-border-container-'+$store.getters.subscriptionTier">
                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-chip class="gradient-chip" small color="white" text-color="black" @click="upgrade" v-bind="attrs" v-on="on">
                                    <v-icon left small>mdi-account-cog</v-icon>
                                    Abo-Details:&nbsp;<span class="font-weight-medium">{{ $store.getters.subscriptionTier }}</span>
                                    <v-icon v-if="!$store.state.client.beta_circle" right small class="ml-1">mdi-chevron-right</v-icon>
                                </v-chip>
                            </template>
                            <p class="pb-0 mb-0" v-if="$store.getters.subscriptionTier !== 'GERINGFÜGIG'">
                                Hier kannst du dein Abo verwalten und deine Rechnungen herunterladen.
                            </p>
                            <p class="pb-0 mb-0" v-else>
                                Hier kannst du unsere Abos einsehen und upgraden.
                            </p>
                        </v-tooltip>
                    </div>

                    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                        <v-card>
                            <v-app-bar dark :color="$store.state.theme.primary">
                                <v-toolbar-title>Menü</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click.native="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <NavigationItems @click.native="dialog = false" :session="session" />
                        </v-card>
                    </v-dialog>
                </v-app-bar>
                <router-view
                    v-if="loaded"
                    :class="this.$vuetify.breakpoint.xs ? 'pa-5' : 'pa-5'"
                    :session="session"
                    ref="currentRoute"
                    @showError="showError"
                    @showInfo="showInfo"
                />
                <v-container v-else>
                    <v-skeleton-loader type="table" elevation="1"></v-skeleton-loader>
                </v-container>
            </v-main>
        </div>
        <SupabaseConnector :session="session" ref="sb" />
    </v-app>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import NavigationItems from './components/NavigationItems.vue';
import Login from './components/Login.vue';
import { supabase } from './supabase';
import SupabaseConnector from './components/SupabaseConnector.vue';
import cipher from './helpers/cipher';
import connector from './helpers/supabase-connector.js';
import dayjs from 'dayjs';
import PasswortVergessen from './components/PasswortVergessen.vue';

export default {
    name: 'App',
    data() {
        return {
            dialog: false,
            loaded: false,
            session: null,
            error: null,
            error_message: '',

            showPlanChangeDialog: false
        };
    },
    components: { NavigationBar, Login, NavigationItems, SupabaseConnector, PasswortVergessen },

    watch: {
        '$route'() {
            this.checkLastPlanChange();
        }
    },

    mounted() {
        supabase.auth.getSession().then(({ data }) => {
            this.session = data.session;
            this.$store.state.session = this.session;

            if (this.session && 'user' in this.session && 'id' in this.session.user) {
                this.$store.state.client.id = this.session.user.id;
            }

            if (this.session !== null && this.$store.state.client.eigenes_schema === null) {
                // TODO: this reference to 'this' is probably not correct
                connector.getDataOnly(this, 'vwkunden', 'id', true).then((data) => {
                    if (data === -1) {
                        // the error has already been displayed
                        return;
                    }
                    else if (data.length > 0) {
                        this.$store.commit('setClient', data[0]);
                        this.checkLastPlanChange();
                        if (this.$store.state.client.eigenes_schema === null) {
                            // redirect to welcome page
                            if (this.$route.name !== 'welcome') this.$router.push({ name: 'welcome' });
                        }
                    } else {
                        // redirect to welcome page
                        if (this.$route.name !== 'welcome') this.$router.push({ name: 'welcome' });
                    }
                });
            }
        });

        supabase.auth.onAuthStateChange((_, _session) => {
            this.session = _session;
            this.$store.state.session = this.session;

            if (this.session && 'user' in this.session && 'id' in this.session.user) {
                this.$store.state.client.id = this.session.user.id;

                // check if provider refresh token is present, if so, store it in local storage
                if (this.session.provider_refresh_token) {
                    localStorage.setItem('provider_refresh_token', this.session.provider_refresh_token);
                }
            }

            if (this.session !== null && this.$store.state.client.eigenes_schema === null) {
                // TODO: this reference to 'this' is probably not correct
                connector.getDataOnly(this, 'vwkunden', 'id', true).then((data) => {
                    if (data === -1) {
                        // error has already been shown
                        return;
                    }
                    else if (data.length > 0) {
                        this.$store.commit('setClient', data[0]);
                        this.checkLastPlanChange();
                        if (this.$store.state.client.eigenes_schema === null) {
                            // redirect to welcome page
                            if (this.$route.name !== 'welcome') this.$router.push({ name: 'welcome' });
                        }
                    } else {
                        // redirect to welcome page
                        if (this.$route.name !== 'welcome') this.$router.push({ name: 'welcome' });
                    }
                });
            }
        });

        if (this.$store.state.data_key) {
            cipher.getAESKeys(this).then(() => (this.loaded = true));
        } else {
            // TODO: redirect user to settings page and prompt to enter data key.
            this.loaded = true;
        }
    },

    methods: {

        reload() {
            window.location.reload();
        },

        checkLastPlanChange() {
            
            if (this.$store.state.client.last_downgrade_on !== null && this.$store.state.client.last_upgrade_on !== null) {
                const last_downgrade_on = dayjs(this.$store.state.client.last_downgrade_on);
                const last_upgrade_on = dayjs(this.$store.state.client.last_upgrade_on);

                // check if last downgrade was after last upgrade and whether the downgrade was within the last 30 days
                if (last_downgrade_on.isAfter(last_upgrade_on) && last_downgrade_on.isAfter(dayjs().subtract(30, 'day'))) {
                    this.showPlanChangeDialog = true;
                    connector.logError(this, {
                        uid: this.$store.state.client.id,
                        message: 'Show frequent plan change dialog'
                    });
                    return;
                }
            }
            this.showPlanChangeDialog = false;
        },

        showError(error) {
            this.$refs.sb.showError(error);
        },

        showInfo(info) {
            this.$refs.sb.showInfo(info);
        },

        // IMPORTANT: ALSO UPDATE THIS IN NavigationBar.vue
        async upgrade() {
            // if (this.$store.state.client.beta_circle) {
                // do nothing
                // return;
            // }

            if (this.$store.state.client.has_stripe_customer_id) {
                // redirect to customer portal
                let function_name = 'create-stripe-customer-portal';
                if (process.env.NODE_ENV === 'development') {
                    function_name = 'create-stripe-customer-portal-test';
                }
                const { data, error } = await supabase.functions.invoke(function_name);

                if (error) {
                    console.error(error);
                    return;
                }

                window.location.href = data?.url;
            } else {
                this.$router.push('/upgrade');
            }
        },
    },
};
</script>

<style scoped>
.gradient-border-container-GERINGFÜGIG, .gradient-border-container-TEILZEIT, .gradient-border-container-VOLLZEIT{
  position: relative;
  display: inline-block;
  border-radius: 24px; /* Dieser Wert sollte dem border-radius des v-chip entsprechen */
}

.gradient-border-container-GERINGFÜGIG::before {
  content: '';
  position: absolute;
  top: -1px; /* Anpassung basierend auf der Dicke des Rahmens */
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(135deg, #EEEEEE, #A9B8C3);
  z-index: -1;
  border-radius: inherit; /* Erbt den border-radius des übergeordneten Containers */
}

.gradient-border-container-TEILZEIT::before {
  content: '';
  position: absolute;
  top: -1px; /* Anpassung basierend auf der Dicke des Rahmens */
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(135deg, #DBECC0, #81C886);
  z-index: -1;
  border-radius: inherit; /* Erbt den border-radius des übergeordneten Containers */
}

.gradient-border-container-VOLLZEIT::before {
  content: '';
  position: absolute;
  top: -1px; /* Anpassung basierend auf der Dicke des Rahmens */
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(135deg, #EDD1DE, #AFAFEC);
  z-index: -1;
  border-radius: inherit; /* Erbt den border-radius des übergeordneten Containers */
}

.gradient-chip {
  position: relative;
  z-index: 1;
  border-radius: 24px; /* Der tatsächliche border-radius des v-chip */
}
</style>

<style>
html {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: #2e495e;
    background-color: #f5f5f5 !important;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
}

.layout {
    /*height: 100vh;*/
    background: #f5f5f5 !important;
}
.navigation-container {
    background-color: #897f7c !important;
}

.content-container {
    background: #f5f5f5 !important;
}

.fixedBottom {
    position: fixed !important;
    bottom: 0 !important;
    width: 90%;
}
</style>
