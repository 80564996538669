<template>
    <v-card>
        <v-card-title class="d-flex flex-column align-start text-subtitle-1 text-sm-h6">
            <span> {{ formTitle }}</span>
            <div v-if="editedItem.selected_customer && editedIndex > -1" class="d-flex align-center">
                <div v-if="editedItem.selected_customer.type === 'client'">
                    <v-icon small left>mdi-account</v-icon>
                    <span class="text-body-2">{{ editedItem.selected_customer.name }}</span>
                    <v-chip v-for="merkmal in editedItem.selected_customer.merkmale" :key="'chip-' + merkmal.id" class="ml-2 font-weight-regular" small>{{ merkmal.merkmal }}</v-chip>
                </div>
                <div v-else-if="editedItem.selected_customer.type === 'institution'">
                    <v-icon small left>mdi-account</v-icon>
                    <span class="text-body-2">{{ editedItem.selected_customer.name }}</span>
                    <v-chip class="ml-2 font-weight-regular" small>Institution</v-chip>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-autocomplete 
                v-if="editedIndex === -1"
                v-model="editedItem.selected_customer" 
                :loading="!loadedCustomersAndAppointments" 
                loader-height="5"
                :disabled="!loadedCustomersAndAppointments" 
                :items="customers" 
                item-text="name" 
                item-value="item_id"
                prepend-inner-icon="mdi-account"
                label="Klient / Institution" 
                outlined dense return-object 
                class="my-5 pb-5 font-weight-medium" 
                hide-details
                placeholder="Bitte wähle einen Klienten / Institution aus" 
                :persistent-placeholder="editedItem.selected_customer === null"
            >
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div v-if="item.type === 'client'">
                            <span>{{ item.name }}</span>
                            <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>{{ merkmal.merkmal }}</v-chip>
                        </div>
                        <div v-else-if="item.type === 'institution'">
                            <span>{{ item.name }}</span>
                            <v-chip class="ml-2" small>Institution</v-chip>
                        </div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div v-if="item.type === 'client'">
                            <span>{{ item.name }}</span>
                            <v-chip v-for="merkmal in item.merkmale" :key="'chip-selection-' + merkmal.id" class="ml-2 font-weight-regular" small>{{ merkmal.merkmal }}</v-chip>
                        </div>
                        <div v-else-if="item.type === 'institution'">
                            <span>{{ item.name }}</span>
                            <v-chip class="ml-2 font-weight-regular" small>Institution</v-chip>
                        </div>
                    </div>
                </template>
            </v-autocomplete>

            <v-alert v-if="hasSelectedInstitution && editedIndex === -1" dense outlined type="info" prominent class="text-body-2 mb-6 mt-5">
                Rechnungen für Institutionen sind für Fälle gedacht, in denen du eine Sammelrechnung für mehrere Klienten erstellen musst, z.B. bei Zusammenarbeit mit einer <strong>Clearing-Stelle</strong> oder dem Projekt <strong>"Gesund aus der Krise"</strong>. 
                Derzeit können diese Rechnungen nicht als PDF oder Word-Dokument erstellt werden und müssen manuell erstellt werden, falls erforderlich. 
                Sie dienen nur zur korrekten Anzeige in der Übersicht und für die Buchhaltung. 
                Wenn du nur eine Rechnung für <strong>einen Klienten</strong> mit einer Institution als Empfänger erstellen möchtest, wähle bitte den Klienten und nicht die Institution aus.
            </v-alert>

            <p class="text-h6 mt-5">
                Rechnungsdetails
            </p>
            <v-row class="mt-3 pb-5">
                <v-col cols="6" :sm="editedItem.beleg_nummer ? 4 : 6" class="my-0 py-0">
                    <v-menu v-model="menu" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense outlined v-model="computedDateFormatted"
                                label="Rechnungsdatum" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" />
                        </template>
                        <v-date-picker first-day-of-week="1" v-model="editedItem.datum" no-title @input="menu = false" />
                    </v-menu>                        
                </v-col>
                <v-col cols="6" :sm="editedItem.beleg_nummer ? 4 : 6" class="my-0 py-0">
                    <v-text-field :disabled="!!editedItem.beleg_nummer" dense outlined v-model="editedItem.nummer" 
                        label="Rechnungsnummer" :hint="hintInvoiceNumber" 
                        :persistent-hint="hasSelectedInstitution || !!editedItem.beleg_nummer" />
                </v-col>
                <v-col v-if="!!editedItem.beleg_nummer" cols="6" sm="4" class="my-0 py-0">
                    <v-text-field disabled dense outlined :value="editedItem.beleg_nummer"
                        label="Belegnummer" persistent-hint hint="Nicht änderbar." />
                </v-col>
            </v-row>

            <v-alert
                v-if="hasInvoiceYearMismatch && editedIndex === -1"
                dense
                outlined
                color="orange"
                type="error"
                class="text-justify"
            >
                <span class="font-weight-bold">Achtung:</span>
                Die Rechnungsnummer enthält das Jahr <strong>{{ invoiceYear }}</strong>, das sich vom Jahr des Rechnungsdatums
                <strong>{{ formatInvoiceDateYear(editedItem.datum) }}</strong> unterscheidet. Bitte prüfe, ob dies korrekt ist.
                <p class="mt-3">
                    Um die Rechnungsnummer auf das Jahr <strong>{{ formatInvoiceDateYear(editedItem.datum) }}</strong> umzustellen, klicke auf <strong>Umstellen</strong>
                    oder überschreibe die vorgeschlagene Rechnungsnummer.
                    Sollte die Rechnungsnummer {{ editedItem.nummer }} richtig sein, kannst du diese Warnung ignorieren.
                </p>
                <v-btn class="mb-3" :color="$store.state.theme.green" outlined @click="updateInvoiceNumberToCurrentYear">
                    Umstellen
                </v-btn>
            </v-alert>

            <v-form v-if="editedIndex > -1" ref="form" v-model="editFormValid">
                <p class="mt-4 text-h6">
                    Zahlungsdetails
                </p>
                <v-row class="d-flex align-start mt-3">
                    <v-col cols="12" sm="6" class="d-flex align-start my-0 py-0 order-2 order-sm-1">
                        <v-menu v-model="menu_paid" :close-on-content-click="true" :open-on-click="!editedItem.beleg_nummer"
                            offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                    dense 
                                    outlined 
                                    v-model="paidDateText"
                                    label="Bezahlt am" 
                                    prepend-inner-icon="mdi-calendar"
                                    :clearable="!editedItem.beleg_nummer"
                                    clear-icon="mdi-close-circle"
                                    @click:clear="clearPaymentDate"
                                    :rules="dateRule"
                                    :disabled="!!editedItem.beleg_nummer"
                                    @input="updateDatePicker"
                                    v-bind="attrs"
                                    v-on="on"
                                    :hint="hintInvoiceNumber" 
                                    :persistent-hint="!!editedItem.beleg_nummer"
                                />
                            </template>
                            <v-date-picker v-if="editedIndex > -1"
                                full-width 
                                first-day-of-week="1" 
                                v-model="editedItem.bezahlt" 
                                no-title 
                                :color="$store.state.theme.green"
                                elevation="1"
                                @input="updateTextField" 
                                class="mt-2 py-3"
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex align-start justify-end my-0 pb-0 pt-1 pr-0 order-1 order-sm-2">
                        <p class="text-body-2 mr-5 pt-1">Betrag in bar erhalten</p>
                        <v-switch :disabled="editedItem.bezahlt === null || !!editedItem.beleg_nummer" v-model="editedItem.bar" inset dense
                            :color="$store.state.theme.green" hide-details class="mt-0">
                        </v-switch>
                    </v-col>
                </v-row>
            </v-form>

            <v-alert v-if="editedIndex > -1 && !!editedItem.beleg_nummer && editedItem.bar === false"
                outlined type="warning" prominent border="left" class="mt-2">
                <span class="font-weight-bold">Achtung:</span>
                Du hast bereits einen Beleg für diese Rechnung erstellt.
                Wenn du die Barzahlung rückgängig machst und jetzt speicherst, wird der Beleg gelöscht!
            </v-alert>

            <p v-if="editedIndex === -1" class="mt-3 mb-3 text-h6">
                Verrechenbare Termine
                <v-chip v-if="editedItem.selected_customer" :color="$store.state.theme.primary" dark small class="ml-2">
                    <v-icon v-if="selected_appointments.length === 0" left small> mdi-cursor-default-click-outline </v-icon>
                    {{ selected_appointments.length === 0 ? 'Wähle die zu verrechnenden Termine aus' : `${selected_appointments.length} ausgewählt` }}
                </v-chip>
            </p>
            <v-data-table 
                v-if="editedIndex === -1" 
                v-model="selected_appointments" 
                :headers="headers_appointments"
                :items="appointments_filtered" 
                item-key="id" 
                show-select 
                class="elevation-1 mt-0 clickable-rows" 
                :items-per-page="-1"
                hide-default-footer 
                @click:row="toggleSelection"
            >
                <template v-slot:item.datum="{ item }">
                    <span>
                        {{ new Date(item.datum).toLocaleString([], {
                            year: 'numeric', month: '2-digit', day: '2-digit', hour:
                                '2-digit', minute: '2-digit'
                        }) }}
                    </span>
                    <v-chip v-if="isAfterNow(item.datum)" :color="$store.state.theme.orange" dark small label class="ml-2">
                        <v-tooltip bottom :color="$store.state.theme.primary">
                            <template v-slot:activator="{ on }">
                                <span class="font-weight-medium" v-on="on">Z</span>
                            </template>
                            Dieser Termin liegt in der Zukunft.
                        </v-tooltip>
                    </v-chip>
                </template>
                <template v-slot:item.name="{ item }">{{ item.nachname }} {{ item.vorname }}</template>
                <template v-slot:item.preis="{ item }">
                    <span v-if="item.dauer">{{ item.preis }}€ ({{ item.dauer }} Minuten)</span>
                    <span v-else>{{ item.preis }} €</span>
                </template>
                <template v-if="editedItem.selected_customer" v-slot:body.append="{ headers }">
                    <tr class="no-hover">
                        <td class="py-3" :colspan="headers.length - 1">
                            <div class="subtitle-1 font-weight-medium black--text">Rechnungsbetrag</div>
                            <div class="subtitle-2 font-weight-regular">= Summe der ausgewählten Termine</div>
                        </td>
                        <td class="py-3">
                            <div class="text-h5 black--text font-weight-bold">
                                {{ amountOfSelectedAppointments }} €
                            </div>
                        </td>
                    </tr>
                </template>

                <template v-slot:no-data>
                    Bitte wählen einen Klienten aus, um dessen verrechenbare Termine zu sehen.
                </template>
            </v-data-table>
        </v-card-text>

        <v-card-actions class="d-flex justify-end mt-5 px-6 pb-5">
            <v-btn :color="$store.state.theme.primary" text @click="$emit('close')">
                Abbrechen
            </v-btn>
            <v-btn :color="$store.state.theme.green" outlined @click="save" :loading="updating_invoice" :disabled="!isFormValid">
                {{ editedIndex === -1 ? 'Erstellen' : 'Speichern' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import connector from '../helpers/supabase-connector.js';
import invoiceHelper from '../helpers/invoices.js';

export default {
    props: ['session', 'editedItem', 'editedIndex', 'appointments', 'invoices', 'n_appointments', 'customers', 'n_customers', 'appointments_filtered'],
    emits: ['close', 'initialize', 'showError', 'showInfo'],

    data() {
        return {

            download_receipt: false,
            selected_appointments: [],

            menu: false,
            menu_paid: false,
            updating_invoice: false,
            headers_appointments: [
                { text: 'Klient', value: 'name' },
                { text: 'Datum', value: 'datum' },
                { text: 'Dienstleistung', value: 'bezeichnung' },
                { text: 'Preis', value: 'preis' }
            ],

            paidDateText: '',
            
            createFormValid: true,
            editFormValid: true,

            dateRule: [
                v => {
                    if (!this.editedItem.beleg_nummer) {
                        return true; // Allow empty date when there's no receipt
                    }
                    return !!v || 'Zahlungsdatum wird benötigt.';
                },
                v => {
                    if (!v) return true; // Skip format validation if empty
                    return /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(\d{4})$/.test(v) || 'Datum im Format TT.MM.JJJJ angeben.';
                },
            ],

            clientRule: [
                v => !!v 
            ],
        }
    },

    computed: {

        invoiceYear() {
            try {
                // If there's no invoice number yet, return null to avoid errors
                if (!this.editedItem.nummer) return null;
                
                // 1) Detect the format (e.g. "{NR}/{JAHR}" or "{JAHR}-{NR}")
                const format = invoiceHelper.getInvoiceFormat(this.editedItem.nummer);

                // 2) Parse the invoice number with the detected format
                const { year } = invoiceHelper.parseInvoiceNumber(this.editedItem.nummer, format);

                // Return the parsed year (e.g. 2024)
                return year;
            } catch (error) {
                // If parsing fails (e.g., invalid format), return null
                return null;
            }
        },

        isFormValid() {
            if (this.editedIndex === -1) {
                // For new invoices, require a customer and at least one appointment
                return this.editedItem.selected_customer && this.selected_appointments.length > 0;
            } else {
                // For existing invoices, use the form validation
                return this.editFormValid;
            }
        },

        hasSelectedInstitution() {
            return this.editedItem.selected_customer && this.editedItem.selected_customer.type === 'institution';
            // return this.editedItem.nummer !== null && this.editedItem.nummer !== undefined && this.editedItem.nummer !== '' && !this.$store.state.client.eigenes_schema
        },

        hintInvoiceNumber() {
            if (this.editedItem.beleg_nummer) {
                // cannot change anymore, as beleg (which includes invoice number) was already created.
                return 'Nicht änderbar da bereits ein Beleg erstellt wurde.';
            } else if (this.hasSelectedInstitution) {
                return 'Eine beliebige Rechnungs-Nr. kann vergeben werden.';
            }
            return '';
        },

        hasInvoiceYearMismatch() {
            // If we couldn’t parse a valid year, we do not show a warning
            if (!this.invoiceYear || this.hasSelectedInstitution || !this.editedItem.datum) return false;

            const invoiceDateYear = dayjs(this.editedItem.datum).year();

            return this.invoiceYear !== invoiceDateYear;
        },

        formTitle() {
            return this.editedIndex === -1 ? 'Neue Rechnung' : `Rechnung Nr. ${this.editedItem.nummer} bearbeiten`
        },

        loadedCustomersAndAppointments() {
            if (this.editedIndex > -1) {
                // if we are editing an invoice, then we do not need to wait, as they are already loaded
                return true;
            }
            return (this.n_customers === this.customers.length) && (this.n_appointments === this.appointments.length)
        },

        computedDateFormatted() {
            return this.formatDate(this.editedItem.datum)
        },

        computedPaidFormatted() {
            return this.formatDate(this.editedItem.bezahlt)
        },

        amountOfSelectedAppointments() {
            try {
                let sum = 0
                this.selected_appointments.forEach((appointment) => {
                    sum += appointment.preis
                })
                return sum
            } catch (error) {
                // in this case, the appointments are not loaded yet, or just an emptry array or whatever
                return 0
            }
        },
    },

    watch: {
        'editedItem.selected_customer': {
            handler(val) {
                this.selected_appointments = [];
            },
        deep: true
      },
      'editedItem.bezahlt': {
            immediate: true,
            handler(newVal) {
                this.paidDateText = this.formatDate(newVal);
            }
        }
    },

    methods: {

        updateInvoiceNumberToCurrentYear() {
            const result = invoiceHelper.updateInvoiceNumberToCurrentYear(
                this.editedItem.nummer, 
                this.editedItem.datum
            );
            
            if (result.status === "success") {
                this.editedItem.nummer = result.invoiceNumber;
            } else {
                this.$emit('showError', { 
                    message: result.message, 
                    error: result.error 
                });
            }
        },

        formatInvoiceDateYear(dateStr) {
            if (!dateStr) return null
            return dayjs(dateStr).format('YYYY')
        },

        toggleSelection(item) {
            const index = this.selected_appointments.findIndex(selected => selected.id === item.id);
            if (index === -1) {
                this.selected_appointments.push(item);
            } else {
                this.selected_appointments.splice(index, 1);
            }
        },

        clearPaymentDate() {
            this.editedItem.bezahlt = null;
            this.paidDateText = '';
            if (this.editedItem.bar) {
                this.editedItem.bar = null;
            }
        },

        updateTextField(newDate) {
            this.paidDateText = this.formatDate(newDate);
        },

        updateDatePicker() {
            if (this.paidDateText === '') {
                this.clearPaymentDate();
            } else {
                const parsedDate = dayjs(this.paidDateText, 'DD.MM.YYYY', true);
                if (parsedDate.isValid()) {
                    this.editedItem.bezahlt = parsedDate.format('YYYY-MM-DD');
                }
            }
        },
        
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('T')[0].split('-')
            return `${day}.${month}.${year}`
        },

        isAfterNow(dateString) {
            const parsed_date = dayjs.tz(dateString, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna')
            const now = dayjs().tz('Europe/Vienna')
            return parsed_date.isAfter(now)
        },

        async save() {

            this.updating_invoice = true

            let invoice = JSON.parse(JSON.stringify(this.editedItem))

            // set the fk_klienten_id and fk_institution_id field
            if (invoice.selected_customer.type === 'client') {
                invoice.fk_klienten_id = invoice.selected_customer.fk_klienten_id
                invoice.fk_institution_id = null
            } else {
                invoice.fk_institution_id = invoice.selected_customer.fk_institution_id
                invoice.fk_klienten_id = null
            }

            delete invoice.id;
            delete invoice.selected_customer
            delete invoice.nachname
            delete invoice.vorname
            delete invoice.rechnungs_betrag
            delete invoice.anzahl_termine
            
            // actually, there is currently no need to delete all these fields, because the createInvoice function only uses the fields that are needed
            invoice.datum = this.editedItem.datum.split('T')[0]

            // check if invoice number is entered.
            if (invoice.nummer === '' || invoice.nummer === null) {
                this.$emit('showError', { 'message': "Eine Rechungsnummer muss vergeben werden." });
                this.updating_invoice = false;
                return;
            }

            // check if invoice number is in correct format, only if we do not issue invoices for institutions
            if (!this.hasSelectedInstitution) {
                // check if invoice number is in correct format
                if (this.$store.state.client.rechnungsnummer_format) {
                    const invoice_regex = invoiceHelper.generateInvoiceRegex(this.$store.state.client.rechnungsnummer_format);
                    if (!invoice_regex.test(invoice.nummer)) {
                        this.$emit('showError', { 'message': "Die Rechungsnummer muss folgendes Format haben: " + this.$store.state.client.rechnungsnummer_format });
                        this.updating_invoice = false;
                        return;
                    }
                } else if (this.$store.state.client.eigenes_schema) {
                    // do nothing, as we do not have a client schema
                } else {
                    const invoice_regex = invoiceHelper.generateInvoiceRegex('{NR}/{JAHR}');
                    if (!invoice_regex.test(invoice.nummer)) {
                        this.$emit('showError', { 'message': "Die Rechungsnummer muss folgendes Format haben: NR/JJJJ (zb. 1/2022)." });
                        this.updating_invoice = false;
                        return;
                    }
                }
            }

            if (this.editedIndex > -1) {
                // updated 
                let invoice_to_insert = { 
                    'bezahlt': invoice.bezahlt, 
                    'nummer': invoice.nummer, 
                    'datum': invoice.datum, 
                    'bar': invoice.bar, 
                };

                let updated = await connector.update(this, 'rechnungen', invoice_to_insert, this.editedIndex);
                if (!updated) {
                    // error has already been displayed
                    this.updating_invoice = false;
                    return;
                }
                this.$emit('initialize');
                this.$emit('close');
                this.updating_invoice = false;
                this.$emit('showInfo', {
                    message: 'Die Rechnung wurde erfolgreich gespeichert.',
                    timeout: 5000
                });

            } else {
                // create new
                let updated_appointments = this.selected_appointments.map(appointment => appointment.id)

                if (updated_appointments.length === 0) {
                    this.$emit('showError', { 'message': "Mindestens ein Termin muss für eine Rechnung ausgewählt werden." })
                    this.updating_invoice = false
                    return
                }

                let invoice_id = await connector.createInvoice(this, invoice, updated_appointments);
                if (!invoice_id) {
                    // error was already displayed
                    this.updating_invoice = false;
                    return;
                }
                this.$emit('initialize');
                this.$emit('close');
                this.updating_invoice = false;
                this.$emit('showInfo', {
                    message: 'Die Rechnung wurde erfolgreich erstellt.',
                    timeout: 5000
                });
            }
        },
    }
}
</script>

<style scoped>
.v-data-table >>> .no-hover:hover {
  background-color: transparent !important;
  cursor: default;
}
.clickable-rows ::v-deep tbody tr {
  cursor: pointer;
}
</style>
