<template>
    <div>
        <v-card
            :class="$vuetify.breakpoint.xs ? 'px-10 pb-10 pt-0' : 'px-10 pb-10 pt-0'"
            :width="$vuetify.breakpoint.xs ? '350px' : '500px'"
        >
            <v-img class="mb-5 pa-0" src="../assets/logo-black.svg" height="120" />
                <h2>Passwort vergessen?</h2>
                <p>
                    Kein Problem. Wir schicken dir eine E-Mail.
                </p>
                <v-text-field type="email" :rules="emailRules" placeholder="E-Mail Adresse" required v-model="email" outlined dense />
                <v-btn
                    class="mt-2"
                    :loading="loading"
                    width="100%"
                    :color="$store.state.theme.green"
                    @click="sendPasswordResetMail"
                    dark
                >E-Mail verschicken</v-btn>
                <div class="d-flex justify-end">
                    <v-btn class="mt-5" text rounded to="/">
                        Zurück zum Login
                        <v-icon right small>
                            mdi-arrow-right-thin
                        </v-icon>
                    </v-btn>
                </div>
        </v-card>

        <v-footer padless absolute>
            <v-row class="d-flex justify-center align-center" no-gutters>
                <v-btn text rounded href="https://zeipsy.com/datenschutz/#app" target="_blank" class="my-2"> Datenschutz </v-btn>
            </v-row>
        </v-footer>
    </div>  
</template>

<script>
import { supabase } from '../supabase';

export default {
    data() {
        return {
            loading: false,
            email: '',
            emailRules: [(v) => !!v || "E-Mail Adresse ist erforderlich", (v) => /.+@.+\..+/.test(v) || "E-Mail Adresse ist ungültig"],
        }
    },

    methods: {
        async sendPasswordResetMail() {
            this.loading = true;
            try {
                const { data, error } = await supabase.auth.resetPasswordForEmail(this.email, {
                    redirectTo: 'https://app.zeipsy.com/einstellungen?missing=passwort-vergessen',
                });

                if (error) {
                    // show error
                    this.$emit('showError', {
                        message: 'Dein Passwort konnte nicht zurückgesetzt werden. Bitte kontaktiere den Support unter contact@zeipsy.com',
                        timeout: 7000,
                        error: error
                    });
                } else {
                    this.$emit('showInfo', {
                        message: 'Bitte überprüfe dein E-Mail Postfach.',
                        timeout: 7000,
                    });
                }
            } catch (error) {
                this.$emit('showError', {
                    message: 'Dein Passwort konnte nicht zurückgesetzt werden. Bitte versuche es noch einmal oder kontaktiere den Support unter contact@zeipsy.com',
                    timeout: 7000,
                    error: error
                });
            }
            finally {
                this.loading = false;
            }
        }
    }
}
</script>